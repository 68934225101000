import React, { useEffect } from 'react';
import { BrowserRouter, } from 'react-router-dom';
import { getUsers } from './redux/userSlice';
import { token, SSO_URL } from './util/constants';
import { isCPMAdmin} from './util/isCPMAdmin';
import { useAppSelector, useAppDispatch } from './app/hooks';
import { Header } from './components/common/header';
import { Dashboard } from './components/resources/Dashboard';
import './App.css';

function App() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(state => state.auth);

  if (!token) window.location.href = SSO_URL;

  useEffect(() => { 
    dispatch(getUsers())
  }, [dispatch]);


  return (
    <div className="App">
      {auth.user.roles && isCPMAdmin(auth.user.roles) ?
        <BrowserRouter>
          <Header />
          <Dashboard />
        </BrowserRouter> :
        <div>Not Found</ div>
      }
    </div>
  );
}

export default App;
