import React from 'react';
import {
    Card,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemText,
  } from '@material-ui/core';

import styles from './ResourceCard.module.css';
import { resourceLinks } from '../../util/api-lib-links';
import { getImagebyResourceName } from '../../util/getImagebyResourceName';

export const ResourceCard = (resourceProps: any) => {
    const API_ENV_TYPE = process.env.REACT_APP_API_ENV_TYPE || 'Test';
        return (
        <>
        <Card {...resourceProps}   sx={{ display: 'flex', height: '100%'}}> 
            <CardHeader title={`${resourceLinks.filter(link => (API_ENV_TYPE === 'Test' ? link.environment === 'test' : link.environment === 'prod')  && link.type === 'api').length} APIs and \n ${resourceLinks.filter(link => (API_ENV_TYPE === 'Test' ? link.environment === 'test' : link.environment === 'prod') && link.type === 'library').length} Libraries in ${API_ENV_TYPE}`} />
            <Divider />
            <List className={styles.resourceList} >
            {resourceLinks.map((link, i) => 
            API_ENV_TYPE === 'Test' && link.environment === 'test' ?
                <ListItem key={link.title} divider={i < resourceLinks.length - 1} className={styles.resourceItem}>
                    <a href={link.url}  >
                        <div className={styles.resourceImage} >
                            {getImagebyResourceName(link.title)}
                        </div>
                        <ListItemText primary={`${link.title}`} />
                    </a>
                </ListItem> :
            API_ENV_TYPE === 'Production' && link.environment === 'prod' ?
                <ListItem key={link.title} divider={i < resourceLinks.length - 1}>
                    <a href={link.url}  >
                        <div className={styles.resourceImage} style={{ height: 300, width: 300 }}>
                            {getImagebyResourceName(link.title)}ß
                        </div>
                        <ListItemText primary={`${link.title}`} />
                    </a>
                </ListItem> : null
            )} 
            </List> 
        </Card>
        </>
        )
}


        

