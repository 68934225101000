import React, {useState, useEffect} from 'react';
import { useAppSelector } from '../../../app/hooks';
import CPM_logo from '../../../assets/CPM_logo.png';
import { SSO_URL } from '../../../util/constants';
import { isCPMAdmin} from '../../../util/isCPMAdmin';
import { Grid, 
    Typography, 
    Tooltip, 
} from '@material-ui/core';
import styles from './header.module.css';

export const Header = () => { 
    const [userRole, setUserRole] = useState('');
    
    const authObj = useAppSelector(state => state.auth);

    // This function navigates to logout SSO window if Admin role is not found from user request
    const handleLogout = () => { 
    window.location.href = `${SSO_URL}/logout`;
    }
    
    useEffect(() => { 
        if (authObj.user.roles && isCPMAdmin(authObj.user.roles)) { 
            setUserRole('CPM Admin')
        } 
    }, [authObj.user.roles]);
    
    return (
        <div className="w-100">
            <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className="headerMainGrid"
            >
                <Grid item xs={4} className="logoGridSection">
                <Tooltip title="Logo" aria-label="CPM Logo">
                    <img src={CPM_logo} alt="CPM Logo" className={styles.logoImage} />
                </Tooltip>
                </Grid>
                    <Grid item xs={3} container justify="flex-end" className={styles.role}>
                    { userRole === 'CPM Admin'} 
                    </Grid>
                <Grid
                item
                xs={5}
                className="userProfileGrid d-flex align-items-center w-100"
                >
                <div className="userProfileSection d-flex align-items-center">
                    <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className="UserNameText"
                    >
                    {userRole}
                    </Typography>
                </div>
                </Grid>
                <Grid>
                    <button onClick={handleLogout} >
                        Logout
                    </button>
                </Grid>
            </Grid>
        </div>
    )

}