import { ReactComponent as CDP_NAVIGATOR} from '../assets/Navigation.svg';
import { ReactComponent as CDP_PAGE_LESSON_COMPONENT } from '../assets/PageLessonComponent.svg';
import { ReactComponent as CDP_LIBRARY_MODULE } from '../assets/CDPeWorkspace.svg';
import { ReactComponent as HERMES_API} from '../assets/Hermes_API.svg';
import { ReactComponent as CDP_STUDENT_INTERFACE_LIBRARY} from '../assets/CDPStudentInterface.svg';
import { ReactComponent as ONEROSTER_CSV_API } from '../assets/OnerosterCSV_API.svg';
import { ReactComponent as ROSTERFEED_API } from '../assets/RosterfeedAPI.svg';
import { ReactComponent as C3PO_API } from '../assets/C3PO.svg';
import { ReactComponent as ASSET_MANAGER_API } from '../assets/AssetManager.svg';
import { ReactComponent as C3PO_CREEK_API } from '../assets/C3PO_Creek.svg';
import { ReactComponent as NCES_API } from '../assets/NCES_API.svg';
import { ReactComponent as IMPORT_API } from '../assets/Import_API.svg';
import { ReactComponent as ELS_API } from '../assets/ELS_API.svg';

export const getImagebyResourceName = (title: string) => { 
    switch(title) { 
        case 'CDP Navigator Library':
            return  <CDP_NAVIGATOR />;
        case 'CDP Page Lesson Component Library':
            return <CDP_PAGE_LESSON_COMPONENT />;
        case 'CDP Library Module':
            return <CDP_LIBRARY_MODULE />;
        case 'Hermes API':
            return <HERMES_API />;
        case 'CDP Student Interface Library':
            return <CDP_STUDENT_INTERFACE_LIBRARY />;
        case 'OneRoster CSV API':
            return <ONEROSTER_CSV_API />;
        case 'Rosterfeed API':
            return <ROSTERFEED_API />;
        case 'C3PO API':
            return <C3PO_API />;
        case 'Asset Manager API':
            return <ASSET_MANAGER_API />;
        case 'C3PO API Creek API':
            return <C3PO_CREEK_API />;
        case 'NCES API':
            return <NCES_API />;
        case 'Import API':
            return <IMPORT_API />;
        case 'ELS API':
            return <ELS_API />;
    }
}