export const resourceLinks = [
    {
        title: 'CDP Navigator Library',
        type: 'library',
        environment: 'test',
        url: 'https://cdp-navigator-lib-test.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'CDP Page Lesson Component Library',
        type: 'library',
        environment: 'test',
        url: 'https://cdp-page-lesson-component-lib-test.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'CDP Library Module',
        type: 'library',
        environment: 'test',
        url: 'https://cdp-eworkspace-lib-module-test.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'Hermes API',
        type: 'api',
        environment: 'test',
        url: 'https://hermes-api-test.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'Hermes API',
        type: 'api',
        environment: 'prod',
        url: 'https://hermes.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'CDP Student Interface Library',
        type: 'library',
        environment: 'test',
        url: 'https://ui-lib-test.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'OneRoster CSV API',
        type: 'api',
        environment: 'test',
        url: 'https://onerostercsv-api-test.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'Rosterfeed API',
        type: 'api',
        environment: 'test',
        url: 'https://roster-feed-test.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'Rosterfeed API',
        type: 'api',
        environment: 'prod',
        url: 'https://rosterfeed-api.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'C3PO API',
        type: 'api',
        environment: 'test',
        url: 'https://c3po-api-test.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'C3PO API',
        type: 'api',
        environment: 'prod',
        url: 'https://c3po-api.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'Asset Manager API',
        type: 'api',
        environment: 'test',
        url: 'https://asset-manager-api-test.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'Asset Manager API',
        type: 'api',
        environment: 'prod',
        url: 'https://asset-manager-api.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'C3PO API Creek API',
        type: 'api',
        environment: 'test',
        url: 'https://c3po-api-creek-test.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'NCES API',
        type: 'api',
        environment: 'test',
        url: 'https://nces-api-test.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'Import API',
        type: 'api',
        environment: 'test',
        url: 'https://import-test.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'Import API',
        type: 'api',
        environment: 'prod',
        url: 'https://import.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'ELS API',
        type: 'api',
        environment: 'test',
        url: 'https://els-api-test.cpm.org',
        role: 'CPM_Admin'
    },
    {
        title: 'ELS API',
        type: 'api',
        environment: 'prod',
        url: 'https://els-api.cpm.org',
        role: 'CPM_Admin'
    },
]