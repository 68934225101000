import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { token } from '../util/constants';
import { RootState } from '../app/store';
import { ELS_API_URL } from '../util/constants';
import { ThunkTypes } from '../util/constants';
import { AuthState, UserRoles } from '../types/users';


const initialState: AuthState = {
  isAuthenticated: false,
  loadingState: 'pending',
  user: [] 
};

export const loadUserState = (
  // state: AuthState,
  payload: UserRoles[]
) => { 
  payload.map(payload => { 
    return (payload.firstName + payload.lastName)
  })
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getUsers = createAsyncThunk(
  ThunkTypes.getUserRoles,
  async (): Promise<UserRoles> => { 
    const response = await axios.get<UserRoles>(`${ELS_API_URL}/account`, {
      headers: { 'x-auth-token': token }
    })
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  });

export const userSlice = createSlice({ 
  name: 'user', 
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
  },
  
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => { 
    builder
      .addCase(getUsers.pending, (state) =>  { 
        state.loadingState = 'pending';
        state.isAuthenticated = false;
      })
      .addCase(getUsers.fulfilled, (state, {payload}) => {
        state.loadingState = 'done';
        state.isAuthenticated = true;
        state.user = payload
      })
      .addCase(getUsers.rejected, (state, {type, error}) => { 
        state.loadingState = 'failed';
        state.isAuthenticated = false;
        state.errorState = {type, error}
      })
  }
})

export const userAuth = (state: RootState) => state.auth;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default userSlice.reducer;