declare const window: Window &
   typeof globalThis & {
       ELS_API_URL: string;
       SSO_URL: string;
       LOGIN_REDIRECT_URI: string 
   }

export const ELS_API_URL = window.ELS_API_URL || process.env.ELS_API_URL || 'https://els-test.cpm.org/ELS/api';

export const SSO_URL = window.SSO_URL || process.env.SSO_URL || 'https://sso-test.cpm.org?redirect_uri=http%3A%2F%2Flocalsso.cpm.org%3A3001';

export const LOGIN_REDIRECT_URI = window.LOGIN_REDIRECT_URI || process.env.LOGIN_REDIRECT_URI || 'https://learningportal-test.cpm.org/';

export enum ThunkTypes { 
    getUserRoles = '/getUserRoles'
}

export const token = document.cookie
  ?.split('; ')
  ?.find((row) => row.startsWith('cpm-sso-token='))
  ?.split('=')[1];