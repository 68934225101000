import React from 'react';
import { ResourceCard } from './ResourceCard';
import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const Dashboard = (props: any) => {

  const DashboardStyles = styled('div')(
    ({theme}) => ({
      backgroundColor: theme.palette.background.default,
      height: '100%',
      width: '100%', 
  })
  );
  
  return (
    <DashboardStyles>
      <Box {...props}
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <ResourceCard sx={{ height: '100%' }}/>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </DashboardStyles>
  )
}
